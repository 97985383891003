body {
  background-color: #505050;
  color: white;
  font-family: sans-serif;
  font-size: 90%;
  margin: 0;
  padding: 0;
}

a {
  color: #337AB7;
  text-decoration: none;
}

h2, h3 {
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
}

h2 {
  font-size: 200%;
}

h3 {
  font-size: 160%;
}

hr {
  border: none;
  border-top: 1px solid #EEE;
}

td, th {
  padding-right: 10px;
  text-align: left;
}

@media (hover: hover) {
  a:hover {
    text-decoration: underline;
  }
}
