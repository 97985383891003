.NavBar {
  background-color: #222;
}

.NavBar ul {
  list-style-type: none;
  overflow: hidden;
  padding: 0;
}

.NavBar li {
  float: left;
}

.NavBar li a {
  color: #9D9D9D;
  margin: auto;
  margin-left: 15px;
  margin-right: 15px;
  vertical-align: middle;
}

.NavBar-brand {
  font-size: 125%;
}

.NavBar-content {
  display: flex;
  justify-content: center;
  height: 50px;
}

@media (hover: hover) {
  .NavBar li a:hover {
    color: white;
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .NavBar li a {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 319px) {
  .NavBar li a {
    margin-left: 5px;
    margin-right: 5px;
  }
}
